

















import { Component, Vue } from 'vue-property-decorator'

@Component
export default class Error404 extends Vue {
    timer = 0 
    second = 5
    mounted() {
        this.timer = setInterval(() => {
            if (this.second === 0) this.$router.go(-1)
            else this.second--
        }, 1000)
    }
    beforeDestroy() {
        clearInterval(this.timer)
    }
}
